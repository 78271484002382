import React from "react";

export default function ResistanceDisplay({ resistance, smdResistance }) {
    return (
        <div className="resistance-display">
            <h3>Calculated Resistance:</h3>
            <p>{resistance}</p>
            {smdResistance && (
                <>
                    <h3>SMD Resistance:</h3>
                    <p>{smdResistance}</p>
                </>
            )}
        </div>
    );
}
